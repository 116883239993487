console.log('Hello World');

// Wait for window load
$(window).on('load', function () {
    // Start scale animation when page is done loading
    $(".loader").addClass('loader--load-done');

    // When loader in playing, disable scroll 
    $('html, body').css({
        'overflow': 'hidden'
    });
    // When loader in playing, hide Hero Section
    $('#heroSection').css({
        'opacity': 0
    });

    if ($(".loader").hasClass('loader--load-done')) {
        // Animate loader off screen after animation is done
        setTimeout(function () {
            $(".loader").fadeOut("slow", function() {
                // Animation complete.
              });
        }, 4000);
    }
});

$(function () {
    checkScrollTopNavbar();
});

// Nav mobile hambuger button toggle
$('#menu-toggle').on('click', function () {
    var toggleState = $(this).hasClass('--active');
    if (toggleState) {
        console.log('active. let\'s deactivate it');
        $(this).removeClass('--active');
        $('nav .nav__menu').removeClass('--show');
        $('body').removeClass('mobile-menu-active');
    } else {
        console.log('not active. let\'s make it active');
        $(this).addClass('--active');
        $('nav .nav__menu').addClass('--show');
        $('body').addClass('mobile-menu-active');

        var tl_mobile_nav = gsap.timeline().from("nav .nav__menu.--show .nav__item", {
            opacity: 0, 
            duration: .5,
            x: 150, 
            stagger: function(index, target, list) {
                return index * 0.15;
            },
            ease: 'circ.out'
        });
    }
});

$('.nav__menu').on('click', function (e) {
    if ($(window).width() <= 768) {
        if (e.target.classList.value != 'nav__menu-wrapper') $('#menu-toggle').trigger("click");
    }
});

// Nav scroll
$(window).bind('scroll', function () {
    checkScrollTopNavbar();
});

function checkScrollTopNavbar() {
    if ($(window).scrollTop() > 1) {
        $('nav').addClass('nav--fixed');
        $('nav').addClass('nav--shrink');
    }
    else {
        $('nav').removeClass('nav--fixed');
        $('nav').removeClass('nav--shrink');
    }
}

// Pop-Up
$('.contact-link').on('click', function () {
    $('#contactPopup').addClass('--active');
    $('body').addClass('--popup-active');

    let $popup = $('#contactPopup .popup__content');
    let popupScrollHeight = $popup.prop('scrollHeight');
    let popupOffsetHeight = $popup.prop('offsetHeight');
    let popupClientHeight = $popup.prop('clientHeight');
    let scrollTopPosition = popupOffsetHeight - popupScrollHeight;
    setTimeout(function () {
        if (popupScrollHeight > popupClientHeight) {
            // Scroll to top if there is a scrollbar
            $popup.scrollTop(scrollTopPosition);
        }
    }, 400);
});

$('#contactPopup .popup__close').on('click', function () {
    $('#contactPopup').removeClass('--active');
    $('body').removeClass('--popup-active');
});

$('.popup .popup__inner').on('click', function (e) {
    var $closeButton = $(this).find('.popup__close');
    if (e.target.classList.value === 'popup__content' || e.target.classList.value === 'popup__inner') $closeButton.trigger("click");
});